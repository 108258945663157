.navbar{
    /* From https://css.glass */
  background: rgba(12, 14, 16, 0.88);
  //border-radius: 16px;
  //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
    .nav-link{
      color: #fff;
    }
  }
.capaProjects{
    height: 400px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
  .spc{
    padding-top: 50px;
    padding-bottom: 50px;
    height: 50vh;
    margin-top: 130px;
    background: #121619;
  }
  


  
.parent {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 8px;
}
    
.div1 {
    grid-row: span 4 / span 4;
}

.div2 {
    grid-row: span 2 / span 2;
}

.div3 {
    grid-row: span 2 / span 2;
    grid-column-start: 2;
    grid-row-start: 3;
}

.div4 {
    grid-row: span 4 / span 4;
    grid-column-start: 3;
    grid-row-start: 1;
}
        


  footer{
    height: 50vh;
    padding-top: 100px;
    padding-bottom: 100px;
    nav{
      .nav-link{
        color: #fff;
      }
    }
  }