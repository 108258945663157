.card-projects{
    .capa{
        img{
           border-radius: 10px;
        }
    }
    p{
        font-size: 15px !important;
    }
    .badge{
        font-weight: 100;
        color: #df3000;
        background:#ec52283d;
    }
    a{
        text-decoration: none;
        font-size: 14px;
    }
}