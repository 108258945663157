@import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: CircularStdBook;
  src: url(./asset/fonts/CircularStdBook.ttf);
}
@font-face {
  font-family: kontora;
  src: url(./asset/fonts/kontora-bold.otf);
}

.face-kontora{
    font-family: kontora !important;
}

body {
  background-color: #0C0E10 !important;
  color: #fff;
  transition: background-color 0.3s, color 0.3s;
  font-family: CircularStdBook !important;
  overflow-x: hidden;
}

.w-100{
    width: 100%;
}
.h-100{
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
}

.avatar{
    width: 128px;
    height: 128px;
    img{
        object-fit: cover;
        object-position: top;
        border-radius:100%;
        width: 128px;
        height: 128px;
    }
}